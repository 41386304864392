import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '../services/authservice';
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/ClientInvoice/CatchAll",
        name: "Billing",
        component: () => import("../views/Billing/Index.vue"),
        meta: {
            title: "Billing",
            breadcrumb: [{ name: "Billing" }],
            checkPrivilege:false
        },
    },
    {
        path: "/ClientInvoice/AddMeter",
        name: "Billing",
        component: () => import("../views/MeterManagement/AddMeter.vue"),
        meta: {
            title: "Billing",
            breadcrumb: [{ name: "Billing" }],
            checkPrivilege:false
        },
    },
    {
        path: "/ClientInvoice/MeterListing",
        name: "Billing",
        component: () => import("../views/MeterManagement/MeterListing.vue"),
        meta: {
            title: "Billing",
            breadcrumb: [{ name: "Billing" }],
            checkPrivilege:false
        },
    },
    {
        path: "/Plan/PlanListing",
        name: "Plan",
        component: () => import("../views/Plan/PlanListing.vue"),
        meta: {
            title: "Plan",
            breadcrumb: [{ name: "Plan" }],
            checkPrivilege:false
        },
    },
    {
        path: "/Plan/ManagePlan/:id",
        name: "ManagePlan",
        component: () => import("../views/Plan/ManagePlanDetail.vue"),
        meta: {
            title: 'Manage Plan',
            breadcrumb: [
                { name: "Plan", link: "/Plan/PlanListing" },
                { name: "Manage" }
            ],
            checkPrivilege: false,
        }
    },
    {
        path: "/Plan/ManageSubscriptionPlanDetail/:id",
        name: "ManageSubscriptionPlanDetail",
        component: () => import("../views/Plan/ManageSubscriptionPlanDetail.vue"),
        meta: {
            title: 'Manage Plan',
            breadcrumb: [
                { name: "Plan", link: "/Plan/PlanListing" },
                { name: "Manage" }
            ],
            checkPrivilege: false,
        }
    },
    {
        path: "/Plan/ServiceListing",
        name: "Plan",
        component: () => import("../views/PlanService/ServiceListing.vue"),
        meta: {
            title: "Service",
            breadcrumb: [{ name: "Service" }],
            checkPrivilege:false
        },
    },
    {
        path: "/Billing/Station",
        name: "Station",
        component: () => import("../views/Billing/StationListing.vue"),
        meta: {
            title: "Station List",
            breadcrumb: [{ name: "Station List" }],
            checkPrivilege:false
        },
    },
    {
        path: "/Billing/InvoiceLog/:requestId?",
        name: "InvoiceLog",
        component: () => import("../views/Billing/InvoiceLogListing.vue"),
        meta: {
            title: "Invoice Log",
            breadcrumb: [{ name: "Invoice Log" }],
            checkPrivilege:false
        }
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
}) 
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/unauthorized') {
        next()
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        if (app) {
            let isCheckPrivilege = true;
            if(to.meta.hasOwnProperty('checkPrivilege'))
            {
                isCheckPrivilege = to.meta.checkPrivilege;
            }
            if(isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else{
                    let pathArray = to.path.split("/");
                    if(pathArray.length > 2){
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else{
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if(controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next()
            }
        }
        else {
            //authService.authenticate(to.path).then(() => {
                console.log('authenticating a protected url:' + to.path);
                next();
           // });
        }
    }
});
export default router

