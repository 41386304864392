
<script>
    import { storeToRefs } from "pinia";  
    import { useloggedUserInfo,moduleStore} from "./stores/UserInfo";
export default {
  data(){
    return{
       showAddWizard:false,
       showAddBookMark:false,
       showNofication:false,
       wizardType:'',
       controllerName:null,
       actionName:null,
       isShowMenuHeader:true
       
    }
  },
    setup() {
        const store = useloggedUserInfo();
        const modulestore = moduleStore();
        const { GetUserInfo } = storeToRefs(store);
        const { GetModuleId } = storeToRefs(modulestore);
        return { GetUserInfo, GetModuleId };
    },
}
</script>
<template>
  <section>
     <ModuleHeader v-if="(GetUserInfo != null && isShowMenuHeader)" :modulemasterid="String(GetModuleId)"></ModuleHeader> 
    <section id="main-content" class="main-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                      <router-view/>
                    </div>
                </div>
            </div>
            <tooltip  :width="250"/>
        </section>
  </section>       
</template>
<style>
/* @import "@/assets/base.css"; */

@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }
}
a{text-decoration:none;}

</style>
